.q-field--outlined .q-field__control, .q-field--filled .q-field__control, .q-field--standout .q-field__control, .q-card--bordered
  border-radius: 8px

.q-btn--rectangle
  border-radius: 12px

.q-field--filled .q-field__control
  border: 1px solid $grey-2

.q-field--outlined.shadowed .q-field__control:before
  border: none
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05)

.q-field--filled.q-field--focused .q-field__control
  border-radius: 12px 12px 0 0

.q-field--outlined .q-field__control, .q-field--standout .q-field__control
  background: white

.q-btn, .q-tab__label
  text-transform: initial

.q-btn:before
  box-shadow: none

.text-medium
  font-weight: 600!important

.q-card
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05)

.q-checkbox--dense .q-checkbox__bg
  border-radius: 4px

.q-dialog__inner > div
  border-radius: 12px

// ################ TEXTS #####################
.text-h1
  font-size: 72px
  font-weight: 800

.text-h2
  font-size: 48px
  font-weight: 800

#staticPage h2
  font-size: 48px
  font-weight: 800
  @media (max-width: $breakpoint-md-max)
    font-size: 30px
    font-weight: 700
  @media (max-width: $breakpoint-xs-max)
    font-size: 24px
    font-weight: 700

.text-h3, #staticPage h3
  font-size: 2.5rem
  font-weight: 800
  @media (max-width: $breakpoint-md-max)
    font-size: 2rem
    font-weight: 700
  @media (max-width: $breakpoint-xs-max)
    font-size: 1.5rem
    font-weight: 700

.text-h4, #staticPage h4
  font-size: 2rem
  font-weight: 800
  @media (max-width: $breakpoint-xs-max)
    font-size: 1.3rem
    font-weight: 700

.text-h5, #staticPage h5
  font-size: 1.5rem
  font-weight: 800
  @media (max-width: $breakpoint-xs-max)
    font-size: 1.2rem
    font-weight: 700

.text-h6, #staticPage h6
  font-size: 1.25rem
  font-weight: 700
  @media (max-width: $breakpoint-xs-max)
    font-size: 1.1rem
    font-weight: 600

.text-subtitle1
  font-size: 1.125rem
  font-weight: 700

.text-body1
  font-size: 1rem
  font-weight: 400

.text-body1-bold
  font-size: 1rem
  font-weight: 700

.text-body2
  font-size: 0.875rem
  font-weight: 400

.text-body2-bold
  font-size: 0.875rem
  font-weight: 700

.text-caption
  font-size: 0.75rem
  font-weight: 400

.text-semibold
  font-weight: 600

.text-title1
  font-size: 1.125rem
