@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'

.avatar-placeholder
  border-radius: 50%
  background: $dimmed-background
  transition: opacity ease $transition-duration
  opacity: 0
  &.avatar-placeholder--show
    opacity: 0.9

.avatar-anchor
  &:focus, &:hover
    .avatar-placeholder
      opacity: 0.9
