// Text colors REALLY USED
$txt_colors: ("primary" : $primary, "secondary" : $secondary, "accent" : $accent, "positive" : $positive, "negative" : $negative, "info" : $info, "warning" : $warning, "dark" : $dark, "white" : $white, "black" : $black, "blue-4" : $blue-4, "blue-6" : $blue-6, "grey-4" : $grey-4, "grey-5" : $grey-5, "red-4" : $red-4, "red-5" : $red-5, "gold-4" : $gold-4, "gold-5" : $gold-5, "purple-4" : $purple-4, "green-4" : $green-4)

@each $color, $variable in $txt_colors
  .text-#{$color}
    color: $variable !important

// Background colors REALLY USED
$bg_colors: ("positive" : $positive, "negative" : $negative, "primary" : $primary, "secondary" : $secondary, "accent" : $accent, "info" : $info, "warning" : $warning, "background" : $background, "tiktok" : $tiktok, "threads" : $threads, "youtube" : $youtube, "twitter" : $twitter, "linkedin" : $linkedin, "facebook" : $facebook, "instagram" : $instagram, "website" : $website, "dark" : $dark, "black" : $black, "white" : $white, "gold-1" : $gold-1, "gold-2" : $gold-2, "gold-3" : $gold-3, "gold-4" : $gold-4, "red-1" : $red-1, "red-2" : $red-2, "green-2" : $green-2, "green-3" : $green-3, "blue-1" : $blue-1, "blue-2" : $blue-2, "blue-3" : $blue-3, "grey-1" : $grey-1, "grey-2" : $grey-2, "grey-3" : $grey-3, "grey-5" : $grey-5, "purple-1" : $purple-1, "purple-2" : $purple-2, "purple-3" : $purple-3, "purple-4" : $purple-4, "primary-light" : $primary-light, "info-light" : $info-light, "warning-light" : $warning-light, "negative-light" : $negative-light, "positive-light" : $positive-light)

@each $color, $variable in $bg_colors
  .bg-#{$color}
    background-color: $variable !important

.bg-gradient
  background: rgb(238,229,254)
  background: linear-gradient(180deg, rgba(238,229,254,1) 0%, rgba(211,243,255,1) 100%)


// Border colors REALLY USED
$bd_colors: ("primary" : $primary, "secondary" : $secondary, "positive" : $positive, "negative" : $negative, "warning" : $warning, "grey" : $grey, "grey-3" : $grey-3, "grey-4" : $grey-4, "blue-3" : $blue-3, "blue-4" : $blue-4, "green-3" : $green-3, "green-4" : $green-4, "red-2" : $red-2, "red-3" : $red-3, "red-4" : $red-4, "gold-2" : $gold-2, "gold-3" : $gold-3, "gold-4" : $gold-4, "purple-3" : $purple-3, "purple-4" : $purple-4)

@each $color, $variable in $bd_colors
  .bd-#{$color}
    border-color: $variable !important

// Border widths REALLY USED
@for $i from 1 through 3
  .bd-#{$i}
    border-style: solid
.bd-1
  border-width: 1px
.bd-1-top
  border-top-width: 1px
.bd-1-bottom
  border-bottom-width: 1px
.bd-2
  border-width: 2px
.bd-2-top
  border-top-width: 2px
.bd-2-bottom
  border-bottom-width: 2px
.bd-3
  border-width: 3px

// Border radius REALLY USED
.br-1-top
  border-top-left-radius: 1px
  border-top-right-radius: 1px
.br-4
  border-radius: 4px
.br-8
  border-radius: 8px
.br-8-top
  border-top-left-radius: 8px
  border-top-right-radius: 8px
.br-8-bottom
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px
.br-10
  border-radius: 10px
.br-12
  border-radius: 12px
.br-20
  border-radius: 20px
.br-20-top
  border-top-left-radius: 20px
  border-top-right-radius: 20px
.br-20-bottom
  border-bottom-left-radius: 20px
  border-bottom-right-radius: 20px
.br-20-left
  border-top-left-radius: 20px
  border-bottom-left-radius: 20px
.br-20-right
  border-top-right-radius: 20px
  border-bottom-right-radius: 20px
.br-25
  border-radius: 25px
.br-25-top
  border-top-left-radius: 25px
  border-top-right-radius: 25px
.br-25-left
  border-top-left-radius: 25px
  border-bottom-left-radius: 25px
.br-30
  border-radius: 30px
.br-50
  border-radius: 50px
