
// rendered markdown is wrapped in p tag
.stl-content-entry p
  margin-bottom: 0

.stl-content-entry a
  color: inherit
  text-decoration: none
  &:focus, &:hover
    text-decoration: underline
