
  .q-uploader
    .q-uploader__header
      background-color: rgba(0, 0, 0, 0)
    .q-uploader__list
      display: none
  .upload-placeholder
    opacity: 0
    transition: all ease 0.5s
    &:hover
      background: rgba(15, 20, 51, 0.5)
      opacity: 1
