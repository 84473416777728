// $font-url-poppins : "https://d1wd4m7r1rdp5c.cloudfront.net/live/platform/fonts/poppins/"
$font-url-poppins : "https://happycab.s3.eu-west-3.amazonaws.com/live/platform/fonts/poppins/"

// poppins-200 - latin
@font-face
  font-family: 'Poppins'
  font-style: normal
  font-display: swap
  font-weight: 200
  // IE9 Compat Modes
  src: url("#{$font-url-poppins}poppins-v15-latin-200.eot")
  // IE6-IE8 / Super Modern Browsers / Modern Browsers / Safari, Android, iOS / Legacy iOS
  src: local(""), url("#{$font-url-poppins}poppins-v15-latin-200.eot?#iefix") format("embedded-opentype"), url("#{$font-url-poppins}poppins-v15-latin-200.woff2") format("woff2"), url("#{$font-url-poppins}poppins-v15-latin-200.woff") format("woff"), url("#{$font-url-poppins}poppins-v15-latin-200.ttf") format("truetype"), url("#{$font-url-poppins}poppins-v15-latin-200.svg#Poppins") format("svg")

// poppins-regular - latin
@font-face
  font-family: 'Poppins'
  font-style: normal
  font-display: swap
  font-weight: 400
  // IE9 Compat Modes
  src: url("#{$font-url-poppins}poppins-v15-latin-regular.eot")
  // IE6-IE8 / Super Modern Browsers / Modern Browsers / Safari, Android, iOS / Legacy iOS
  src: local(""), url("#{$font-url-poppins}poppins-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("#{$font-url-poppins}poppins-v15-latin-regular.woff2") format("woff2"), url("#{$font-url-poppins}poppins-v15-latin-regular.woff") format("woff"), url("#{$font-url-poppins}poppins-v15-latin-regular.ttf") format("truetype"), url("#{$font-url-poppins}poppins-v15-latin-regular.svg#Poppins") format("svg")

// poppins-500 - latin
@font-face
  font-family: 'Poppins'
  font-style: normal
  font-display: swap
  font-weight: 500
  // IE9 Compat Modes
  src: url("#{$font-url-poppins}poppins-v15-latin-500.eot")
  // IE6-IE8 / Super Modern Browsers / Modern Browsers / Safari, Android, iOS / Legacy iOS
  src: local(""), url("#{$font-url-poppins}poppins-v15-latin-500.eot?#iefix") format("embedded-opentype"), url("#{$font-url-poppins}poppins-v15-latin-500.woff2") format("woff2"), url("#{$font-url-poppins}poppins-v15-latin-500.woff") format("woff"), url("#{$font-url-poppins}poppins-v15-latin-500.ttf") format("truetype"), url("#{$font-url-poppins}poppins-v15-latin-500.svg#Poppins") format("svg")

// poppins-600 - latin
@font-face
  font-family: 'Poppins'
  font-style: normal
  font-display: swap
  font-weight: 600
  // IE9 Compat Modes
  src: url("#{$font-url-poppins}poppins-v15-latin-600.eot")
  // IE6-IE8 / Super Modern Browsers / Modern Browsers / Safari, Android, iOS / Legacy iOS
  src: local(""), url("#{$font-url-poppins}poppins-v15-latin-600.eot?#iefix") format("embedded-opentype"), url("#{$font-url-poppins}poppins-v15-latin-600.woff2") format("woff2"), url("#{$font-url-poppins}poppins-v15-latin-600.woff") format("woff"), url("#{$font-url-poppins}poppins-v15-latin-600.ttf") format("truetype"), url("#{$font-url-poppins}poppins-v15-latin-600.svg#Poppins") format("svg")

// poppins-700 - latin
@font-face
  font-family: 'Poppins'
  font-style: normal
  font-display: swap
  font-weight: 700
  // IE9 Compat Modes
  src: url("#{$font-url-poppins}poppins-v15-latin-700.eot")
  // IE6-IE8 / Super Modern Browsers / Modern Browsers / Safari, Android, iOS / Legacy iOS
  src: local(""), url("#{$font-url-poppins}poppins-v15-latin-700.eot?#iefix") format("embedded-opentype"), url("#{$font-url-poppins}poppins-v15-latin-700.woff2") format("woff2"), url("#{$font-url-poppins}poppins-v15-latin-700.woff") format("woff"), url("#{$font-url-poppins}poppins-v15-latin-700.ttf") format("truetype"), url("#{$font-url-poppins}poppins-v15-latin-700.svg#Poppins") format("svg")
