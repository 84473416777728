.mapboxgl-marker
  cursor: pointer

.nodrag
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -o-user-select: none
  -ms-user-select: none
  user-select: none

  -webkit-user-drag: none
  -khtml-user-drag: none
  -moz-user-drag: none
  -o-user-drag: none
  -ms-user-drag: none
  user-drag: none

.nopointers
  pointer-events: none

.preserve-lines
  white-space: pre-line
  word-wrap: break-word

.stl-content-container
  max-width: 100%
  @media (min-width: 0px)
    &.stl-content-container--xsmall
      max-width: 36rem
    &.stl-content-container--small
      max-width: 48rem
    &.stl-content-container--large
      max-width: 64rem
    &.stl-content-container--mlarge
      max-width: 80rem
    &.stl-content-container--xlarge
      max-width: 90rem
    &.stl-content-container--xxlarge
      max-width: 120rem

.social-link
  transition: all .3s ease
  -webkit-transition: all .3s ease
  filter: brightness(0)

  &:hover
    filter: none

.text-balance
  text-wrap: balance

// HC classes
.hc-round
  border-radius: 50%

.hc-shadow
  box-shadow: rgba(0.9412, 0.9216, 0.8000, 0.08) 0px 1px 6px

.hc-focused
  position: relative
  z-index: 2002

.hc-link
  text-decoration: none
  color: $dark
  &:hover
    color: $primary

.hc-square
  width: 100%

.hc-square:after
  content: ""
  display: block
  padding-bottom: 100%

.hc-text-gradient
  background: -webkit-linear-gradient(45deg, $secondary, $primary)
  background-clip: text
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.hc-bg-gradient
  background: -webkit-linear-gradient(45deg, rgba($secondary, 0.3), rgba($primary, 0.3))
